
import React, { Component } from 'react';
import Slider from "react-slick";


class HeaderCarousel extends Component {
    render() {
      var settings = {
  
      dots: false,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      speed: 800,
      autoplaySpeed: 5000,
      cssEase: "linear",
      fade: true,
      //centerMode: true,
      //adaptiveHeight: true,
      //variableWidth: true

      };
      return (
          <div id="header-carousel">
        <Slider {...settings}>
         
          <div>
            <img alt="1" src="../../assets/headerCarousel/syn-vasc.jpg" />  
            <div className="caption">Vasculature model of the human brain extracted from MRI scan sets and converted to OpenGL</div>
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/dti3.jpg" />  
            <div className="caption">Tractography / Diffusion MRI image, 64 directional full brain tracking</div>
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/dti4.jpg" />  
            <div className="caption">Tractography / Diffusion MRI image, 64 directional full brain tracking (full view)</div>
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/fs3.jpg" />  
            <div className="caption">Feng Shui Urbanism - from graduate work in architecture at Columbia University, New York</div>
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/alethio4.jpg" />  
            <div className="caption">Visualization showing the transfer of wrapped ETH in Defi (2018-2019)</div>
          </div>
          <div>
            <img alt="1" src="../../assets/headerCarousel/alethio6.jpg" />  
            <div className="caption">Visualization showing the transfer of wrapped ETH in Defi (2018-2019)</div>
          </div>
          
          <div>
            <img alt="1" src="../../assets/headerCarousel/scopus.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/mapping.jpg" />  
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/3dneuron.jpg" />  
          </div>
          
          <div>
            <img alt="1" src="../../assets/headerCarousel/draw2.jpg" />  
          </div>


          
          <div>
            <img alt="1" src="../../assets/headerCarousel/symtboard.jpg" />  
          </div>






          <div>
            <img alt="1" src="../../assets/headerCarousel/audi2.jpg" />  
          </div>





          <div>
            <img alt="1" src="../../assets/headerCarousel/synapse-led.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/brainIndex2.jpg" />  
          </div>



          <div>
            <img alt="1" src="../../assets/headerCarousel/brainvasc.jpg" />  
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/kv.jpg" />  
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/city1.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/rein.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/arch4.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/maya3.jpg" />  
          </div>






          
        </Slider>

        </div>

      );
    }
  }



  export default HeaderCarousel




  /*



          <div>
            <img alt="1" src="../../assets/headerCarousel/syn-vasc.jpg" />  
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/dti1.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/alethio4.jpg" />  
          </div>
          <div>
            <img alt="1" src="../../assets/headerCarousel/alethio6.jpg" />  
          </div>
          
          <div>
            <img alt="1" src="../../assets/headerCarousel/audi2.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/mapping.jpg" />  
          </div>
          <div>
            <img alt="1" src="../../assets/headerCarousel/3dneuron.jpg" />  
          </div>
        


          <div>
            <img alt="1" src="../../assets/headerCarousel/icons.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/symtboard.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/fs2.jpg" />  
          </div>
          <div>
            <img alt="1" src="../../assets/headerCarousel/gsusa-app.jpg" />  
          </div>
          <div>
            <img alt="1" src="../../assets/headerCarousel/gsapp.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/gs-sys2.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/synapse-led.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/dti2.jpg" />  
          </div>
          


          <div>
            <img alt="1" src="../../assets/headerCarousel/brainindex2.jpg" />  
          </div>



          <div>
            <img alt="1" src="../../assets/headerCarousel/brainindex.jpg" />  
          </div>



          <div>
            <img alt="1" src="../../assets/headerCarousel/sculpt.jpg" />  
          </div>

          
          <div>
            <img alt="1" src="../../assets/headerCarousel/arch.jpg" />  
          </div>

          <div>
            <img alt="1" src="../../assets/headerCarousel/kv.jpg" />  
          </div>


          <div>
            <img alt="1" src="../../assets/headerCarousel/maya2.jpg" />  
          </div>
        



          */