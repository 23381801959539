
import React, { Component } from 'react';

class Nav extends Component {
  
  
  render() {

    const whiteHeader = this.props.whiteHeader;

    const style = {
      color: (whiteHeader === true) ? 'white': '#ddd',
      display: (whiteHeader === true) ? 'inline': 'none',
    };



    return (
        <div id="nav" style={style}>
          <div id="jochen">Jochen Hartmann</div>
          <div id="menu">
            <div className="item"><a href="#info" rel="noopener noreferrer">Info</a></div>    
          </div>
          </div>

    );
  }
}

export default Nav;
