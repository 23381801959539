import React, { Component } from 'react';
import ProjectCarousel from './projectCarousel';
import Tags from './tags';



class Project extends Component {
  

  render() {

    let { images, description } = this.props;
    let { title, tags, client } = this.props.data;
    
    
    return (
      <div className="project">
        <div className="client">{client}</div>
        <div className="title">{title}</div>
        <ProjectCarousel images={images}/>
        <div className="description">{description}
          <Tags tags={tags} />
        </div>
      </div>
    );
  }
}

export default Project;
