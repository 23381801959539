import React, { Component } from 'react';

class SectionIntro extends Component {
  render() {

    let {title, description} = this.props;


    return (
      <div className="section">
        <div className="section-title">{title}</div>
        <p>{description}</p>
      </div>
      
    );
  }
}

export default SectionIntro;
