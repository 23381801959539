import React, { Component } from 'react';

import './App.css';
import SectionIntro from './components/sectionIntro';
import Project from './components/project';
import Nav from './components/nav';
import Footer from './components/footer';


import "react-responsive-carousel/lib/styles/carousel.min.css";
import HeaderCarousel from './components/headerCarousel';

let allImages = {

  alethio: [
    "../../assets/projects/alethio/sept30-2018.jpg",
    "../../assets/projects/alethio/alethio1.jpg",
    "../../assets/projects/alethio/alethio2.jpg",
    "../../assets/projects/alethio/alethio3.jpg",
  ]
  ,
  geo: [
    
    "../../assets/projects/geo/amman.jpg",
    "../../assets/projects/geo/amman2.jpg",

    "../../assets/projects/geo/geomap2.jpg",
    "../../assets/projects/geo/geo2.jpg",
    "../../assets/projects/geo/locations.jpg",

  ],

  gsusa: [
    "../../assets/projects/gsusa/home.png",
    "../../assets/projects/gsusa/screens2.png",
    "../../assets/projects/gsusa/gs-micro.jpg",


  ],

  synapse: [
    "../../assets/projects/synapse/synapse-room.jpg",
    "../../assets/projects/synapse/synapse-screens2.jpg",
    "../../assets/projects/synapse/led.gif",


    
    "../../assets/projects/synapse/synapse-people.jpg",

    "../../assets/projects/synapse/screens1.jpg",
    "../../assets/projects/synapse/screens2.jpg",
    "../../assets/projects/synapse/kandel.jpg",

    
    
    
    
    
    "../../assets/projects/synapse/animation_brain-small.gif",
    "../../assets/projects/synapse/app.png",
    "../../assets/projects/synapse/neuron.jpg",
    "../../assets/projects/synapse/vasculature1.jpg",




  ]
  ,

  audi: [
    
    "../../assets/projects/audi/audi2.jpg",
    
    "../../assets/projects/audi/ingols.jpg",
    "../../assets/projects/audi/audi4.jpg",

    "../../assets/projects/audi/audi3.jpg",

    

  ],

  ctrl: [

    "../../assets/projects/ctrl/ipad.png",
    "../../assets/projects/ctrl/ctrl2.png",
  ],

  fyrn: [
    "../../assets/projects/fyrn/fyrn1.png",
    "../../assets/projects/fyrn/fyrn-interfaces.jpg",
  ],

  swell: [
    "../../assets/projects/swell/swell1.jpg",
    "../../assets/projects/swell/swell2.jpg",
    "../../assets/projects/swell/swell3.jpg",
  ],

  csr: [

    "../../assets/projects/csr/csr1.jpg",
    "../../assets/projects/csr/csr2.png",
    "../../assets/projects/csr/csr-site-32c.gif",
  ],

  symt: [
    "../../assets/projects/symtactics/symt-install.jpg",
    "../../assets/projects/symtactics/uneven-cover.jpg",
    "../../assets/projects/symtactics/symt1board.jpg",
    
    "../../assets/projects/symtactics/symt-moma.jpg",
    "../../assets/projects/symtactics/symt-moma2.jpg",


  ],

  kw: [
    "../../assets/projects/kw/kw.png",
    "../../assets/projects/kw/kw-animation-master-32.gif",

  ]
  


};

let projectData = {

  alethio: {
    client: "Alethio",
    title: 'Decentralized Finance',


    tags: ['react', 'd3', 'ethereum']
  },

  geo: {
    client: "CLUSTER",
    title: "Geospatial Technology",
    tags: ['node', 'react', 'mapbox-gl', 'leaflet', 'angularjs', 'material design']
  },

  gsusa: {
    client: "Girlscouts of America",
    title: "Juliette Prototype",
    tags: ['react-native', 'aws', 'microservices', 'system architecture', 'ux']

  },

  synapse: {
    client: "Zuckerman Institute, Columbia University",
    title: "The Brain Index",
    tags: ['data-viz', 'creative direction', 'react', 'game programming', 'open-gl']

  },

  audi: {
    client: "Audi Data Intelligence",
    title: "Cognitive State Management App",
    tags: ['python', 'geo-spatial', 'super-collider', 'rapid-development', 'data-viz', 'data sonification'],

  },

  ctrl: {

    client: "CTRL",
    title: "Mobile order processing",
    tags: ['shopify-api', 'angular', 'material-design'],
  },

  fyrn: { 
    client: 'Fyrn',
    title: 'Shopify theme development',
    tags: ['shopify-theme', 'liquid', 'SASS', 'Javascript'],
    
  },


  swell: { 
    client: 'S\'well',
    title: 'E-commerce development',
    tags: ['shopify-theme', 'e-commerce', 'creative direction', 'SEO'],
    
  },

  csr: {
   
    client: 'Center for Spatial Research, Columbia University',
    title: 'Web development',
    tags: ['angular', 'drupal', 'SASS', 'Javascript', 'SVG'],
    


  },

  symt: {

    client: 'Museum of Modern Art',
    title: 'Symtactics',
    tags: ['game design', 'research', 'architecture', 'illustration', 'design'],

  },

  kw: {
    client: 'Karlsson Wilker',
    title: 'Graphic Design Space Invaders',
    tags: ['game design', 'phaser', 'javascript'],
  }

}



class App extends Component {

  constructor(props) {
    super(props);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = { whiteHeader: true }
  }


  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }


  handleScroll() {
    if (window.pageYOffset > 650) {
      this.setState({whiteHeader: false})
    } else if (window.pageYOffset < 200) {
      this.setState({whiteHeader: true})
    }
  }




  render() {
    const {whiteHeader }= this.state;

    return (
      <div className="App">
      
        <header className="App-header">
          <Nav whiteHeader={whiteHeader}/>
          <HeaderCarousel />
          
        </header>
        <br/>
        <SectionIntro key={1001}
          title="Application Design and Development"
          description="Experience in developing software applications using modern frameworks and stacks including data visualization, geospatial functionality, responsive interface design and mobile game development."/>
  <Project 
          key={1}
          data={projectData.alethio} 
          images={allImages.alethio}
          description={['Interactive data visualization created with ', 
          <a key={999} href="https://aleth.io" rel="noopener noreferrer" target="_blank">Aleth.io</a>, 
          ' for recent presentation at SXSW. Shown is the transfer of wrapped ether into the MakerDAO and other external accounts on the Ethereum blockchain for every day up to the present. Part of a larger project of analyzing systemic risk in DeFi.']}
          />


      
<Project 
          key={2}
          data={projectData.geo} 
          images={allImages.geo}
          description={['Mapping applications for the Cairo Lab for Urban Studies supporting mobile location capture, real time data processing and geospatial analysis. Responsive front-end implemented in ReactJS and MapboxGL supports multiple user interface languages, customizable map styles, custom geoJSON processing and 3d geometry.', 
          ]}
          />

        <Project 
          key={3}
          data={projectData.gsusa} 
          images={allImages.gsusa}
          description={['Developed a working mobile application prototype to connect over 1.2m active users. Built various front-end components in React Native/Expo including integration between Unity and React Native. Also developed system architecture for a microservices backend running on AWS including detailed system diagrams, cost estimates and budgets. ' 
          ]}
          />

          
        <Project 
          key={4}
          data={projectData.synapse} 
          images={allImages.synapse}
          description={['Project lead for the Brain Index, a permanent installation in the lobby of the Jerome L Greene science center, home of the Zuckerman Institute for interdisciplinary neuroscience.', 
          ' Project involved design of the physical structure and robotic track systems as well as content development, budgeting and creative direction. Developed several novel methods to extact and process brain models and science assets including converting neuroscience experiments into interactive games for the touchscreens. Collaboration with ', 
          <a key={998} href="https://www.arch.columbia.edu/faculty/195-laura-kurgan" rel="noopener noreferrer" target="_blank">Laura Kurgan</a>, ', ',
          <a key={997} href="https://journalism.columbia.edu/faculty/mark-hansen" rel="noopener noreferrer" target="_blank">Mark Hansen</a>, ' and ',
          <a key={996} href="https://about.me/caitlinshure" rel="noopener noreferrer" target="_blank">Caitlin Shure</a>, '.'
          ]}
          />

<Project 
          key={5}
          data={projectData.audi} 
          images={allImages.audi}
          description={['Developed a working prototype geo-spatial system capable of analyzing and sonifying over 20 GB of driving data generated by test drives in customized Audi A4\'s around the city of Ingolstadt, Germany. System generates custom audio environments in response to driving behavior to mitigate focus and distraction.', 


          'System desiged in collaboration with ', 
          <a key={995} href="http://www.momoaraki.com" rel="noopener noreferrer" target="_blank">Momo Araki</a>,
          
          
          ' and ', 
          <a key={993} href="http://dantaeyoung.com/" rel="noopener noreferrer" target="_blank">Dan Taeyoung Lee</a>,
           
          
          ' during a workshop held at ', 
          <a key={994} href="https://www.newinc.org/" rel="noopener noreferrer" target="_blank">NEW INC</a>,
          
          
          
          '. Other invited participants included Accurat & Moritz Stefaner.'          
          ]}
          />



<Project 
          key={9}
          data={projectData.csr} 
          images={allImages.csr}
          description={['Responsive front-end for the ', 
          <a key={993} href="http://c4sr.columbia.edu" rel="noopener noreferrer" target="_blank">Center for Spatial Research</a>, 
          ' that supports complex menu systems, modular UI components and customized SVG graphics.']}
        />
  



          <SectionIntro   key={1002} 
            title="E-Commerce"
            description="Over 5 years of experience developing eCommerce platforms. Shopify Partner with expertise in application design, theme and web development, and marketing."
          />


      <Project 
          key={6}
          data={projectData.ctrl} 
          images={allImages.ctrl}
          description={['An e-commerce application designed to run on tablets that directly integrates into the Shopify platform. The front-end architecture is built in AngularJS and conforms to material-design based interface principles. It runs consistently across devices and supports customer signature capture on tablets, multiple users and permissions, notifications and automatic scaling and generation of images.']}
        />



      <Project 
          key={7}
          data={projectData.fyrn} 
          images={allImages.fyrn}
          description={['Mobile first Shopify theme with custom interface for ',
          <a key={992} href="https://www.fyrn.com" rel="noopener noreferrer" target="_blank">San Francisco based furniture makers</a>, '. Custom functionality within Shopify allows client to update images, re-arrange content, replace links and slideshows and edit templates.']}
        />


<Project 
          key={8}
          data={projectData.swell} 
          images={allImages.swell}
          description={['Web development, technical direction and custom system design for ', 
          <a key={991} href="https://www.swellbottle.com" rel="noopener noreferrer" target="_blank">S'well</a>, 
          ' a leading brand of sustainable water bottles and the fastest growing woman owned company in the US.']}
        />
  


  <SectionIntro   
            title="Game Development"
            description="Over 7 years of experience in designing and developing various print and video games."
          />


<Project 
          key={100}
          data={projectData.kw} 
          images={allImages.kw}
          description={['Built a web-based version of space invaders for ',
          <a key={888} href="https://karlssonwilker.com/" rel="noopener noreferrer" target="_blank">Karlsson Wilker</a>, 
          ' an award winning design studio in New York.'
          ]}
        />
<Project 
          key={99}
          data={projectData.symt} 
          images={allImages.symt}
          description={['Participation in a year-long research effort to examine architectural solutions to solving global inequality for the ', 
          
          <a key={990} href="https://www.moma.org/calendar/exhibitions/1400" rel="noopener noreferrer" target="_blank">Uneven Growth</a>, 
          ' exhibit at the Museum of Modern Art.',
          ' Working as part of the ', 
          <a key={990} href="http://www.networkarchitecturelab.org/" rel="noopener noreferrer" target="_blank">Network Architecture Lab</a>, 
          ', I designed and developed an asymmetrical multi-player game that simulates the dynamics of tactical urbanism set in a fictional Hong Kong in the year 2047. The game exploits the natural tensions between cooperation and competition and was printed in an edition of 30,000. Project was a collaboration with ', 
          <a key={889} href="http://www.varnelis.net/" rel="noopener noreferrer" target="_blank">Kazys Varnelis</a>, ', Momo Araki, Robert Sumrell and Neil Donnelly. The game was exhibited both at the Museum of Modern Art in New York and the MAK in Vienna.']}
        />






          <Footer />
     
      </div>
    );
  }
}

export default App;
