import React, { Component } from 'react';





class Footer extends Component {


    constructor(props) {
        super(props);

        this.state = {mobile: false};

        

    }

    componentDidMount() {
        if (window.innerWidth < 900) this.setState({mobile: true});
      }
  

  render() {


        const leftSide = {};
        let mid = {};
        let right = {};
        if (this.state.mobile === true) {
            leftSide.display = 'none';
            
            mid = {
                width: '20%'
            }

            right = {
                width: '50%'
            }

        }




    return (
      <footer id="info">
        <div className="left" style={leftSide}>
            <img src="../../assets/jochen-profile-01-bw-sq.jpg" alt="Jochen Hartmann" />
        </div>
        <div className="mid" style={mid}>
            <p>Jochen Hartmann</p>
            <p>Berlin, Germany</p>
            <p>tel: +49 159 01828 041</p>
            {

                (this.state.mobile) ? '' : <p>jochen.hartmann@gmail.com</p>

            }
            <br/>
            
        </div>
        <div className="right" style={right}>
            <p style={{fontSize: '200%'}}>Info</p>
            <br/>
            <p><a href="mailto:jochen.hartmann@gmail.com" target="_blank" rel="noopener noreferrer">Contact</a></p>
            <p><a href="https://github.com/vomc" target="_blank" rel="noopener noreferrer">Github</a></p>
            <p><a href="https://www.linkedin.com/in/jochenhartmann1" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
            <p><a href="/assets/JochenHartmann_CV_2018_berlin.pdf" target="_blank" rel="noopener noreferrer">Download CV / Resume</a></p>
            <br/>
            <p style={{opacity: 0.5}}>&copy; 2019 - Jochen Hartmann</p>



        </div>
          



      </footer>
      
    );
  }
}

export default Footer;
