import React, { Component } from 'react';


class Tags extends Component {
  



  render() {

    return (
      <div className="tags">{

        this.props.tags.map((tag, i) => <div key={i+10000} className={tag}>{tag}</div>)

      }</div>
    );
  }
}

export default Tags;
