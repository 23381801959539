
import React, { Component } from 'react';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 


class ProjectCarousel extends Component {

    constructor(props) {
        super(props);
        this.state = { photoIndex: 0, isOpen: false, mobile: false};

    }

    componentDidMount() {
      if (window.innerWidth < 900) this.setState({mobile: true});
    }

    render() {
      var settings = {

        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true



            }
          }

        ]
      };
      const { photoIndex, isOpen } = this.state;
      

      const slideStyle = { margin: 'auto' }

      if (this.state.mobile === true) {
        slideStyle.height = 200
      }  else {
        slideStyle.height = 500 
      }

    

      return (
        <div className="project-carousel">
          <Slider {...settings}>{
            
            (this.props.images.map((image, i) => 
              <div key={i+1000}><img style={slideStyle} alt="1xxx" 
                        key={i} 
                        src={image} 
                        />
              <div key={i+100} className="view-larger" onClick={() => {
                            this.setState({ photoIndex: i })
                            this.setState({ isOpen: true })
                        }}>*</div>
              </div>
              ))



            }
        </Slider>

        {isOpen && (
          <Lightbox
            mainSrc={this.props.images[photoIndex]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            
          />
        )}
        </div>
    );
  }
}



  export default ProjectCarousel


